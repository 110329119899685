import BaseAPI from '../BaseAPI';

class Technologies extends BaseAPI {
  constructor(props) {
    super(props);
    this.baseUrl = `/list?country=${process.env.REACT_APP_COUNTRY?.toUpperCase()}`;
  }

  /**
   * Gets a list of technology
   *
   * @returns {Promise<*|Error>}
   */
  fetchAll() {
    const url = `/technology${this.baseUrl}`;

    return this.get({ url });
  }

  fetchPriceListApi(params) {
    const id = params?.id;

    const type = params?.type;

    let url = '';

    if (type === 'main') {
      url = `/price-list${this.baseUrl}&cinemaId=${id}`;
    } else {
      url = `/price-list${this.baseUrl}&id=${id}`;
    }

    return this.get({ url });
  }

  // FFC Note
  getNote() {
    const url = `/ffcloginhint/list?country=${process.env.REACT_APP_COUNTRY?.toUpperCase()}`;


    return this.get({ url });
  }

  // Get Main Url
  getMainUrl({ params }) {
    const search = params;
    const url = `/redirect-url${this.baseUrl}&redirectUrl=${search}`;

    return this.get({ url });
  }

  fetchPriceGastronomyListApi(params) {
    const id = params?.id;

    const type = params?.type;

    let url = '';

    if (type === 'main') {
      url = `/price-list${this.baseUrl}&cinemaId=${id}`;
    } else {
      url = `/price-list${this.baseUrl}&id=${id}`;
    }

    return this.get({ url });
  }

  fetchCinemaExtraInfo() {
    const url = `/cinema${this.baseUrl}`;


    return this.get({ url });
  }

  fetchFaqInfo() {
    const url = `/faq-contact/list?country=${process.env.REACT_APP_COUNTRY?.toUpperCase()}`;

    return this.get({ url });
  }

  fetchLandingInfo({ params }) {
    const id = params?.id;

    let url = '';

    if (id !== undefined) {
      if (isNaN(id)) {
        // url = `/landing-page/list?country=${process.env.REACT_APP_COUNTRY?.toUpperCase()}&SiteName=${id}`;
        url = `/landing-page/list?country=${process.env.REACT_APP_COUNTRY?.toUpperCase()}&objectName=${id}`;
      }
      else {
        url = `/landing-page/list?country=${process.env.REACT_APP_COUNTRY?.toUpperCase()}&siteId=${id}`;
      }
    } else {
      url = `/landing-page/list?country=${process.env.REACT_APP_COUNTRY?.toUpperCase()}`;
    }

    return this.get({ url });
  }

  fetchHallTicket({ params }) {
    const id = params;

    const url = `/hallinfo/list?country=${process.env.REACT_APP_COUNTRY?.toUpperCase()}&id=${id}`;

    return this.get({ url });
  }

  registration(body) {
    const form_data = new FormData();

    for (const key in body) {
      form_data.append(key, body[key]);
    }
    const url = '/sendform/contact-request';


    return this.postFormData({ url, body: form_data });
  }

  newsLetterRegistration(body) {
    const url = '/sendform/newsletter-registration';


    return this.post({ url, body });
  }

  fetchAdvertisement() {
    const url = `/advertisingbanner${this.baseUrl}`;

    return this.get({ url });
  }

  fetchNewsLetterContent() {
    const url = `/newsletter-popup${this.baseUrl}`;


    return this.get({ url });
  }

  fetchPrivacyPolicy() {
    const url = `/privacypolicy${this.baseUrl}`;


    return this.get({ url });
  }

  /**
   * Gets a list of technology events
   *
   * @returns {Promise<*|Error>}
   */
  fetchEvents() {
    const url = `/technology-main-site${this.baseUrl}`;

    return this.get({ url });
  }

  fetchRedirectUrlAll() {
    const url = `/subdomainforward${this.baseUrl}`;
    return this.get({ url });
  }

  fetchLegalInfoDocument() {
    const url = `/legalinfodocuments${this.baseUrl}`;
    return this.get({ url });
  }

  getStepCheckout() {
    const url = `/hintpurchasecheckout${this.baseUrl}`;
    return this.get({ url });
  }
}

export default Technologies;
