export const TECHNOLOGIES_REQUEST = 'technologies/TEHNOLOGIES_REQUEST';
export const TECHNOLOGIES_SUCCESS = 'technologies/TEHNOLOGIES_SUCCESS';

export const TECHNOLOGY_EVENTS_REQUEST = 'technologies/TECHNOLOGY_EVENTS_REQUEST';
export const TECHNOLOGY_EVENTS_SUCCESS = 'technologies/TECHNOLOGY_EVENTS_SUCCESS';

export const NEWSLETTER_POPUP_REQUEST = 'technologies/NEWSLETTER_POPUP_REQUEST';
export const NEWSLETTER_POPUP_SUCCESS = 'technologies/NEWSLETTER_POPUP_SUCCESS';

export const PRIVACY_POLICY_REQUEST = 'technologies/PRIVACY_POLICY_REQUEST';
export const PRIVACY_POLICY_SUCCESS = 'technologies/PRIVACY_POLICY_SUCCESS';

export const TECHNOLOGY_REDIRECT_REQUEST = 'technologies/TECHNOLOGY_REDIRECT_REQUEST';
export const TECHNOLOGY_REDIRECT_SUCCESS = 'technologies/TECHNOLOGY_REDIRECT_SUCCESS';

export const LEGAL_INFO_DOCUMENT_REQUEST = 'technologies/LEGAL_INFO_DOCUMENT_REQUEST';
export const LEGAL_INFO_DOCUMENT_REQUEST_SUCCESS = 'technologies/LEGAL_INFO_DOCUMENT_REQUEST_SUCCESS';
