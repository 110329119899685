import {
  TECHNOLOGIES_REQUEST,
  TECHNOLOGIES_SUCCESS,
  TECHNOLOGY_EVENTS_REQUEST,
  TECHNOLOGY_EVENTS_SUCCESS,
  NEWSLETTER_POPUP_REQUEST,
  PRIVACY_POLICY_REQUEST,
  NEWSLETTER_POPUP_SUCCESS,
  PRIVACY_POLICY_SUCCESS,
  TECHNOLOGY_REDIRECT_SUCCESS,
  TECHNOLOGY_REDIRECT_REQUEST,
  LEGAL_INFO_DOCUMENT_REQUEST,
  LEGAL_INFO_DOCUMENT_REQUEST_SUCCESS
} from './types';

export const requestTechnologies = () => ({
  type: TECHNOLOGIES_REQUEST
});

export const successTechnologies = technologies => ({
  type: TECHNOLOGIES_SUCCESS,
  payload: { technologies }
});

export const requestTechnologyEvents = () => ({
  type: TECHNOLOGY_EVENTS_REQUEST
});

export const successTechnologyEvents = events => ({
  type: TECHNOLOGY_EVENTS_SUCCESS,
  payload: { events }
});

export const requestRedirectDomainURL = () => ({
  type: TECHNOLOGY_REDIRECT_REQUEST
});

export const successRedirectDomainURL = redirecurl => ({
  type: TECHNOLOGY_REDIRECT_SUCCESS,
  payload: { redirecurl }
});
export const requestLegalInfoDocument = () => ({
  type: LEGAL_INFO_DOCUMENT_REQUEST
});

export const successLegalInfoDocument = legalInfoData => ({
  type: LEGAL_INFO_DOCUMENT_REQUEST_SUCCESS,
  payload: { legalInfoData }
});

export const requestFooterNewsLetter = () => ({
  type: NEWSLETTER_POPUP_REQUEST
});
export const requestFooterPrivacyPolicy = () => ({
  type: PRIVACY_POLICY_REQUEST
});

export const successNewsLetter = newsletter => ({
  type: NEWSLETTER_POPUP_SUCCESS,
  payload: { newsletter }
});
export const successPrivacyPolicy = privacyPolicy => ({
  type: PRIVACY_POLICY_SUCCESS,
  payload: { privacyPolicy }
});
