/* eslint-disable react-hooks/exhaustive-deps  */
/* eslint-disable array-callback-return  */
/* eslint-disable jsx-a11y/anchor-is-valid  */
import React, { useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import useConfig from "app/hooks/useConfig";
import { Container, Button, Svg } from "app/components";
import { useState } from "react";
import { Page, Title, Code } from "./styles";

const propTypes = {
  error: PropTypes.shape({
    code: PropTypes.oneOfType([
      PropTypes.number.isRequired,
      PropTypes.string.isRequired
    ]),
    message: PropTypes.string.isRequired,
    error: PropTypes.object.isRequired,
    fetchMainUrl: PropTypes.func.isRequired,
    mainUrl: PropTypes.oneOfType([PropTypes.array, PropTypes.any])
  }),

  location: PropTypes.shape({
    search: PropTypes.string.isRequired,
    pathname: PropTypes.string.isRequired
  }).isRequired,
  getLocations: PropTypes.func.isRequired,
  dev: PropTypes.shape({
    message: PropTypes.string,
    stack: PropTypes.string
  }),

  clearError: PropTypes.func.isRequired,
  reportError: PropTypes.func.isRequired,
};

const defaultProps = {
  error: null,
  dev: null
};

const errorMock = {
  code: 404,
  message: "error_page.errors.not_found",
  error: null
};

const devErrorMock = {
  code: 0,
  message: "error_page.errors.ui"
};

const ERROR_TYPES = {
  ui: "ui",
  network: "network"
};

const APIERRORS = {
  1: "error_page.errors.failed_fetch"
};

const Error = ({
  error,
  clearError,
  dev,
  reportError,
  fetchMainUrl,
  mainUrl,
  location,
  getLocations,
}) => {
  const [t, i18n] = useTranslation();
  const { routes } = useConfig();
  const [show, setShow] = useState(false);
  const { message, code } = dev ? devErrorMock : error || errorMock;
  const fallbackUrl = routes.fallback.pattern;
  const id = window?.location?.pathname.slice(1);
  const countryCode = process.env.REACT_APP_COUNTRY;
  const buttonProps = dev
    ? {
      onClick: () => {
        window.location.pathname = fallbackUrl;
      }
    }
    : { to: fallbackUrl };

  setTimeout(() => {
    setShow(true);
  }, 1500);

  useEffect(() => {
    fetchMainUrl(id);
    getLocations();
  }, []);

  const findredirect = mainUrl?.filter(item => {
    if (window.location.href.includes(item?.redirectUrls?.[0]?.redirectUrl)) {
      return item;
    }
  });

  if (mainUrl?.length > 0) {
    if (
      window.location.href.match(mainUrl?.[0]?.redirectUrls?.[0]?.redirectUrl)
    ) {
      if (i18n.language === "en" || i18n.language === 'en-US') {
        sessionStorage.removeItem("movie")
        window.location.replace(findredirect?.[0]?.redirectUrls?.[0]?.mainUrlEN)
      } else {
        sessionStorage.removeItem("movie")
        window.location.replace(findredirect?.[0]?.redirectUrls?.[0]?.mainUrl);
      }
    }
  }

  useEffect(
    () => () => {
      clearError();
    },
    []
  );

  useEffect(() => {
    if (dev) {
      reportError(dev, ERROR_TYPES.ui);
    }

    if (error) {
      reportError(error.error, ERROR_TYPES.network);
    }
  }, [dev, error]);

  const getErrorMesssage = useCallback(
    (type, msg) => {
      if (type === 1) {
        const errorMessage = t(APIERRORS[type]);
        const periodIndex = errorMessage.indexOf(")");
        console.log("errorMessage", periodIndex);


        if (periodIndex !== -1) {
          const firstPart = errorMessage.slice(0, periodIndex + 1);
          const secondPart = errorMessage.slice(periodIndex + 1).trim();
          return (
            <>
              <span>{firstPart}</span>
              <br />
              <br />
              <span>{secondPart}</span>
            </>
          );
        }
        return errorMessage;
      }
      return t(msg);
    },
    [t]
  );
  return (
    <>
      {show ? (
        <Page>
          <Container className="container">
            <Title>{getErrorMesssage(code, message)}</Title>
            {(countryCode === 'aut' && code !== 1) &&
              <>
                <Code>{code}</Code>
                <Svg id="error" />
              </>
            }
            {(countryCode === 'aut' && code !== 1) &&
              <Button {...buttonProps} isDefault>
                {t("common.to_home_page")}
              </Button>
            }
          </Container>
        </Page>
      ) : null}
    </>
  );
};

Error.defaultProps = defaultProps;
Error.propTypes = propTypes;
export default Error;
